.container {
    height: 24px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 78px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbbbbb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 3px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 38px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 3px;
}

input:checked + .slider {
    background-color: #673ffa;
}

.switchDisabled {
    opacity: 0.7;
    cursor: default;
    background-color: #bbbbbb !important;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
}

.onSwitch {
    display: none;
}

.onSwitch,
.offSwitch {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 25%;
    left: 25%;
    font-size: 14px;
    font-family: Verdana, sans-serif;
}

.onSwitch {
    top: 12px;
}

.offSwitch {
    left: auto;
    right: 0;
    top: 12px;
}

input:checked + .slider .onSwitch {
    display: block;
}

input:checked + .slider .offSwitch {
    display: none;
}
